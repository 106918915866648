import { createApiClient } from "../../helpers/api-client";

export default function createNotificationsApi(csrf) {
    const api = createApiClient(csrf);

    return {
        async fetchInitialNotifications() {
            return await api("/notifications/", "GET");
        },

        async fetchMoreNotifications(uid) {
            return await api(`/notifications/${uid}`, "GET");
        },

        async fetchMarkAllNotificationsAsRead() {
            return await api(`/notifications/mark-all-as-read`, "POST");
        },

        async fetchMarkNotificationAsRead(uid) {
            return await api(`/notifications/${uid}/mark-as-read`, "POST");
        },
    };
}
