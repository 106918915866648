export const verticalSwiperOptions = {
    direction: "vertical",
    slidesPerView: "auto",
    freeMode: true,
    watchSlidesProgress: true,
    scrollbar: {
        el: ".swiper-scrollbar",
        draggable: true,
    },
    mousewheel: {
        forceToAxis: true,
    },
};
