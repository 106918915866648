const luxSelect = {
    initializeCustomSelect() {
        var x = document.getElementsByClassName("lux-select-dropdown");
        var l = x.length;

        for (let i = 0; i < l; i++) {
            if (x[i].classList.contains("initialized")) {
                // Remove any existing custom elements for refreshing
                const existingSelected = x[i].querySelector(".select-selected");
                const existingItems = x[i].querySelector(".select-items");
                if (existingSelected) existingSelected.remove();
                if (existingItems) existingItems.remove();
                x[i].classList.remove("initialized");
            }

            const selElmnt = x[i].getElementsByTagName("select")[0];
            const selClasses = selElmnt.classList.value;
            const ll = selElmnt.length;

            // Create the selected item div
            const a = document.createElement("div");
            a.setAttribute("class", "select-selected " + selClasses);
            a.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;
            x[i].appendChild(a);

            // Create the options list container div
            const b = document.createElement("div");
            b.setAttribute("class", "select-items select-hide " + selClasses);

            let widestWidth = 0;
            for (let j = 0; j < ll; j++) {
                const tempDiv = document.createElement("div");
                tempDiv.style.position = "absolute";
                tempDiv.style.visibility = "hidden";
                tempDiv.style.whiteSpace = "nowrap";
                tempDiv.innerHTML = selElmnt.options[j].innerHTML;
                document.body.appendChild(tempDiv);
                widestWidth = Math.max(widestWidth, tempDiv.offsetWidth);
                document.body.removeChild(tempDiv);

                const c = document.createElement("div");
                c.innerHTML = selElmnt.options[j].innerHTML;

                if (selElmnt.options[j].disabled) {
                    c.classList.add("option-disabled");
                }

                if (j === selElmnt.selectedIndex) {
                    c.classList.add("same-as-selected");
                }

                c.addEventListener("click", function () {
                    if (this.classList.contains("option-disabled")) return;

                    const s =
                        this.parentNode.parentNode.getElementsByTagName(
                            "select",
                        )[0];
                    const h = this.parentNode.previousSibling;

                    for (let k = 0; k < s.length; k++) {
                        if (s.options[k].innerHTML === this.innerHTML) {
                            s.selectedIndex = k;
                            h.innerHTML = this.innerHTML;

                            const event = new Event("change");
                            s.dispatchEvent(event);

                            const y =
                                this.parentNode.getElementsByClassName(
                                    "same-as-selected",
                                );
                            for (let m = 0; m < y.length; m++) {
                                y[m].classList.remove("same-as-selected");
                            }
                            this.classList.add("same-as-selected");
                            break;
                        }
                    }
                    h.click();
                });
                b.appendChild(c);
            }

            x[i].style.setProperty("--widestOption", `${widestWidth}px`);
            x[i].appendChild(b);
            x[i].classList.add("initialized");

            a.addEventListener("click", function (e) {
                e.stopPropagation();
                closeAllSelect(this);
                this.nextSibling.classList.toggle("select-hide");
                this.classList.toggle("select-arrow-active");

                if (!this.nextSibling.classList.contains("select-hide")) {
                    document.addEventListener("click", closeAllSelect);
                } else {
                    document.removeEventListener("click", closeAllSelect);
                }
            });
        }
    },
};

window.luxSelect = luxSelect;

function closeAllSelect(elmnt) {
    if (elmnt?.target?.classList.contains("option-disabled")) {
        return;
    }

    const x = document.getElementsByClassName("select-items");
    const y = document.getElementsByClassName("select-selected");
    for (let i = 0; i < y.length; i++) {
        if (elmnt === y[i]) {
            continue;
        } else {
            y[i].classList.remove("select-arrow-active");
        }
    }
    for (let i = 0; i < x.length; i++) {
        x[i].classList.add("select-hide");
    }
}

document.addEventListener("click", closeAllSelect);

// Initialize or refresh custom dropdowns
luxSelect.initializeCustomSelect();
