export const DEBOUNCE_DELAY = 200;

export function debounce(fn, delay = DEBOUNCE_DELAY) {
    let timer;

    const debounced = (...args) => {
        if (timer) {
            clearTimeout(timer);
        }

        timer = setTimeout(() => {
            fn(...args);
            timer = null;
        }, delay);
    };

    debounced.cancel = () => {
        if (timer) {
            clearTimeout(timer);
            timer = null;
        }
    };

    return debounced;
}
