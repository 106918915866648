const luxDropdownWrappers = document.querySelectorAll(".lux-dropdown-wrapper");
const luxDropdownContent = document.querySelectorAll(".lux-dropdown-content");
const luxDropdownToggles = document.querySelectorAll(
    "*[data-toggle='lux-dropdown'] .lux-dropdown-btn",
);

luxDropdownToggles.forEach(function (toggle) {
    const dropdownWrapper = toggle.closest(".lux-dropdown-wrapper");
    toggle.addEventListener("click", function (e) {
        e.stopPropagation();
        dropdownWrapper
            ?.querySelector(".lux-dropdown-wrapper.active")
            ?.classList.remove("active");
        dropdownWrapper.classList.toggle("active");
    });
});

document.addEventListener("click", function (e) {
    const activeDropdown = document.querySelector(
        ".lux-dropdown-wrapper.active .lux-dropdown-content",
    );

    if (!activeDropdown || !activeDropdown.contains(e.target)) {
        luxDropdownWrappers.forEach(function (wrapper) {
            wrapper.classList.remove("active");
        });
    }
});
