const luxAccordions = document.querySelectorAll(".lux-accordion");

luxAccordions.forEach(function (accordion) {
    const luxToggles = accordion.querySelectorAll(
        "*[data-toggle='lux-accordion']",
    );
    const luxContents = accordion.querySelectorAll(".content");

    luxToggles.forEach(function (toggle) {
        const dataTarget = toggle.getAttribute("data-target");
        const target = document.getElementById(dataTarget);

        toggle.addEventListener("click", function () {
            misc.slideToggle(target);

            luxContents.forEach(function (content) {
                if (content !== target) {
                    misc.slideUp(content);
                }
            });

            luxToggles.forEach(function (el) {
                if (el !== toggle) {
                    el.setAttribute("aria-expanded", "false");
                }
            });

            const isExpanded = toggle.getAttribute("aria-expanded") === "true";
            toggle.setAttribute("aria-expanded", isExpanded ? "false" : "true");
        });
    });
});
