import $ from "./leaked-jquery";
import Cookies from "js-cookie";
import "bootstrap";
import "jquery-unveil";

const misc = {
    lazyload() {
        $("img.lazy, source.lazy, source.lazy-hidden, video.lazy").unveil(
            200,
            function () {
                $(this).on("load", function () {
                    this.style.opacity = 1;
                });
            },
        );
        $("img.transparent").unveil(200, function () {
            $(this).on("load", function () {
                this.style.opacity = 0.2;
            });
        });
    },
    fixIfBodyIsSmallThanViewport() {
        const body = document.body;
        const html = document.documentElement;
        const height = Math.max(
            body.scrollHeight,
            body.offsetHeight,
            html.clientHeight,
            html.scrollHeight,
            html.offsetHeight,
        );
        const vh = Math.max(
            document.documentElement.clientHeight || 0,
            window.innerHeight || 0,
        );
        if (height < vh) {
            $("html").css(
                "background-color",
                $("footer .footer_wrap").css("background-color"),
            );
        }
    },
    initializeTooltips() {
        $('[data-toggle="tooltip"]').tooltip();
    },
    lazyLoadOnTabChange() {
        $('a[data-toggle="tab"]').on("shown.bs.tab", (event) => {
            $(window).trigger("scroll.unveil"); //trigger lazyLoad of any images that was hidden.
        });
    },
    showMore: function () {
        var next_page_url = $(".btn-see-more-blogposts").data("url");

        $(".blog-posts-wrapper")
            .append(
                '<div class="logo-loader"><div class="logo-spinner"></div></div>',
            )
            .addClass("loading");

        $.getJSON(next_page_url, function (data, item) {
            $(".btn-wrapper").remove();
            $(".logo-loader").remove();
            $(".blog-posts-wrapper").append(data).removeClass("loading");
        });
    },
    setCookieWithExpiryNextDay(name, value, time) {
        let now = new Date();
        let expiresTime = new Date();
        let timeZoneOffset = now.getTimezoneOffset() * 60 * 1000;

        expiresTime.setDate(now.getDate() + 1);
        expiresTime.setHours(time, 0, 0, 0);
        expiresTime = new Date(expiresTime.getTime() - timeZoneOffset);

        Cookies.set(name, value, { expires: expiresTime, path: "/" });
    },
    copyToClipboard(selector, id, replacementElement) {
        const element = document.querySelector(selector);
        const button = document.getElementById(id);

        if (
            element &&
            button &&
            navigator?.clipboard?.writeText &&
            window.isSecureContext
        ) {
            const originalText = button.innerText;
            navigator.clipboard.writeText(element.innerText);
            const buttonWidth = button.offsetWidth;
            button.style.minWidth = `${buttonWidth}px`;
            button.innerHTML = replacementElement;

            setTimeout(() => {
                button.innerText = originalText;
            }, 2500);
        }
    },
    kioskRedirect(triggerByInput, url, milliseconds) {
        clearTimeout(window.kioskRedirect);

        if (triggerByInput) {
            const inputs = document.querySelectorAll("input");

            for (const input of inputs) {
                input.addEventListener("focus", () => {
                    clearTimeout(window.kioskRedirect);

                    window.kioskRedirect = setTimeout(() => {
                        window.location.replace(url);
                    }, milliseconds);
                });
            }
        } else {
            window.kioskRedirect = setTimeout(() => {
                window.location.replace(url);
            }, milliseconds);
        }
    },
    slideUp(element, duration = 300) {
        element.style.transition = `height ${duration}ms ease-out, padding ${duration}ms ease-out, margin ${duration}ms ease-out`;
        element.style.height = element.offsetHeight + "px";
        element.offsetHeight;
        element.style.overflow = "hidden";
        element.style.height = "0";
        element.style.paddingTop = "0";
        element.style.paddingBottom = "0";
        element.style.marginTop = "0";
        element.style.marginBottom = "0";

        setTimeout(() => {
            element.style.display = "none";
            element.style.removeProperty("height");
            element.style.removeProperty("padding-top");
            element.style.removeProperty("padding-bottom");
            element.style.removeProperty("margin-top");
            element.style.removeProperty("margin-bottom");
            element.style.removeProperty("overflow");
            element.style.removeProperty("transition");
            element.classList.remove("expanded");
        }, duration);
    },
    slideDown(element, duration = 300) {
        element.style.removeProperty("display");
        let display = window.getComputedStyle(element).display;
        if (display === "none") display = "block";
        element.style.display = display;

        let height = element.offsetHeight;
        element.style.overflow = "hidden";
        element.style.height = "0";
        element.style.paddingTop = "0";
        element.style.paddingBottom = "0";
        element.style.marginTop = "0";
        element.style.marginBottom = "0";
        element.offsetHeight;
        element.style.transition = `height ${duration}ms ease-out, padding ${duration}ms ease-out, margin ${duration}ms ease-out`;
        element.style.height = height + "px";
        element.style.paddingTop = "";
        element.style.paddingBottom = "";
        element.style.marginTop = "";
        element.style.marginBottom = "";

        setTimeout(() => {
            element.style.removeProperty("height");
            element.style.removeProperty("overflow");
            element.style.removeProperty("transition");
            element.classList.add("expanded");
        }, duration);
    },
    slideToggle(element, duration = 300) {
        if (window.getComputedStyle(element).display === "none") {
            this.slideDown(element, duration);
        } else {
            this.slideUp(element, duration);
        }
    },
};

window.misc = misc;

$(() => {
    misc.lazyload();
    misc.initializeTooltips();
    misc.fixIfBodyIsSmallThanViewport();
    misc.lazyLoadOnTabChange();
});
