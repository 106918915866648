import { createHtmlElement } from "../../helpers/dom-manipulation";

export function migrateSwiper(objects, element) {
    objects.forEach((obj) => {
        const slide = createHtmlElement(obj);
        element.appendChild(slide);
    });
}

export function getSlideDataId(slide) {
    if (!slide || !slide.dataset || !slide.dataset.id) {
        return null;
    }
    return slide.dataset.id.split("-")[1];
}

export function getNumberOfLoadedSlides(swiper) {
    return swiper._swiper.slides.length;
}

export function getMostRecentSlideId(swiper) {
    const slide = swiper._swiper.slides[getNumberOfLoadedSlides(swiper) - 1];
    return getSlideDataId(slide);
}

export function getFullyVisibleSlides([fullyVisible, visible], reachedEnd) {
    const fullyVisibleSlides = Array.from(
        document.querySelectorAll(fullyVisible),
    );

    if (reachedEnd) {
        const visibleSlides = document.querySelectorAll(visible);
        const lastSlide = visibleSlides[visibleSlides.length - 1];

        if (lastSlide && !fullyVisibleSlides.includes(lastSlide)) {
            fullyVisibleSlides.push(lastSlide);
        }
    }
    return fullyVisibleSlides;
}
