import { tryCatch } from "./middleware";

export function createApiClient(csrf) {
    const apiRequest = tryCatch(async function (
        url,
        method,
        body = null,
        extraHeaders = {},
    ) {
        const headers = {
            "Content-Type": "text/html",
            "X-CSRF-Token": csrf,
            ...extraHeaders,
        };

        const options = { method, headers };
        if (method === "POST" && body) {
            options.body = JSON.stringify(body);
        }

        const response = await fetch(url, options);
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return await response.json();
    });

    return apiRequest;
}
