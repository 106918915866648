const NOTIFICATIONS = {
    loader: ".lux-notifications-wrapper > .loader",
    empty: ".lux-notifications-wrapper > .empty",
    dropdown: ".lux-notifications > .lux-dropdown-btn",
    swiperWrapper: ".lux-notifications-wrapper .swiper-wrapper",
    markAllAsRead: "mark-all-as-read",
    unreadAmount: ".notifications > .unread-amount",
    fullyVisible: ".swiper-slide-fully-visible .lux-notification-slide.unread",
    visible: ".swiper-slide-visible .lux-notification-slide.unread",
};

export default NOTIFICATIONS;
