import { captureException } from "@sentry/browser";

export function tryCatch(fn) {
    if (fn.constructor.name === "AsyncFunction") {
        return async function (...args) {
            try {
                return await fn(...args);
            } catch (error) {
                captureException(error);
                throw error;
            }
        };
    } else {
        return function (...args) {
            try {
                const result = fn(...args);

                if (result && typeof result.then === "function") {
                    return result.catch((error) => {
                        captureException(error);
                        throw error;
                    });
                }

                return result;
            } catch (error) {
                captureException(error);
                throw error;
            }
        };
    }
}

export function withGuard(...steps) {
    return async function (...args) {
        for (const step of steps) {
            if (typeof step === "function") {
                const result =
                    step.constructor.name === "AsyncFunction"
                        ? await step(...args)
                        : step(...args);

                if (typeof result === "boolean" && !result) {
                    return;
                }
            }
        }
    };
}
