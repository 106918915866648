export function createHtmlElement(html) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
    const content = doc.body.firstChild;
    return content.cloneNode(true);
}

export function setElementVisibility(selector, visible = false) {
    const element = document.querySelector(selector);
    if (!element) return;
    element.classList.toggle("d-none", !visible);
}
